<template src="./Bank.pug" lang="pug"></template>

<script>

  import { mapActions } from 'vuex'
  import {mask} from 'vue-the-mask'

  export default{
    directives: {mask},
    name: 'Bank',

    data: () => ({
        transferType: "1",
        pixKey: "",
        pixKeyCel: '',
        pixKeyCpf: '',
        pixKeyEmail: '',
        pixKeyRandon: '',

        valid: true,
        rules: {
          required: value => !!value || 'Campo obrigatório',
          email: v => !v || /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) || 'Digite um e-mail válido',
          twoWords: value => (!!value && (/(\w.+\s).+/).test(value)) || 'Digite o nome completo',
        },
        nome: '',
        cpf: '',
        celular: '',
        agencia: '',
        conta: '',
        digito: '',
        tipoConta: {tipo:'Conta corrente', val:'corrente'},
        tipoContaItems: [{tipo:'Conta corrente', val:'corrente'}, {tipo:'Conta poupança', val:'poupanca'}],
        pixType: {tipo:'celular', val:'celular'},
        pixTypeItems: [{tipo:'celular', val:'celular'}, {tipo:'CPF', val:'cpf'}, {tipo:'e-mail', val:'email'}, {tipo:'aleatória', val:'aleatoria'}],
        bankCod: '',
        showMainList: true,
        addressZipCode: '',
        addressStreet: '',
        addressNumber: '',
        addressComplement: '',
        addressNeighborhood: '',
        addressCity: '',
        addressState: '',
        bankList: [],
        stateList: [
          {nome: 'Acre', sigla: 'AC'},
          {nome: 'Alagoas', sigla: 'AL'},
          {nome: 'Amapá', sigla: 'AP'},
          {nome: 'Amazonas', sigla: 'AM'},
          {nome: 'Bahia', sigla: 'BA'},
          {nome: 'Ceará', sigla: 'CE'},
          {nome: 'Espírito Santo', sigla: 'ES'},
          {nome: 'Goiás', sigla: 'GO'},
          {nome: 'Maranhão', sigla: 'MA'},
          {nome: 'Mato Grosso', sigla: 'MT'},
          {nome: 'Mato Grosso do Sul', sigla: 'MS'},
          {nome: 'Minas Gerais', sigla: 'MG'},
          {nome: 'Pará', sigla: 'PA'},
          {nome: 'Paraíba', sigla: 'PB'},
          {nome: 'Paraná', sigla: 'PR'},
          {nome: 'Pernambuco', sigla: 'PE'},
          {nome: 'Piauí', sigla: 'PI'},
          {nome: 'Rio de Janeiro', sigla: 'RJ'},
          {nome: 'Rio Grande do Norte', sigla: 'RN'},
          {nome: 'Rio Grande do Sul', sigla: 'RS'},
          {nome: 'Rondônia', sigla: 'RO'},
          {nome: 'Roraima', sigla: 'RR'},
          {nome: 'Santa Catarina', sigla: 'SC'},
          {nome: 'São Paulo', sigla: 'SP'},
          {nome: 'Sergipe', sigla: 'SE'},
          {nome: 'Tocantins', sigla: 'TO'},
          {nome: 'Distrito Federal', sigla: 'DF'}
        ]
    }),
    computed: {
      banks() {
        return this.$store.state.users.banks
      },
      validCPF () { // validação de CPF
        const validCPF = []
        const isValidCPF =
          v => this.validateCPF(v.trim().replace(/[^0-9]/g, '')) || `CPF inválido`

        validCPF.push(isValidCPF)
        return validCPF
      },
      userId(){
        return this.$jwt.decode(this.$store.state.auth.token).data.id
      },
      userData(){
        return this.$store.state.users.userData
      }
    },
    methods: {
      ...mapActions(['updateUser', 'getBanks', 'getUser']),

      async getUserData(){
        await this.getUser({ id: this.userId })
        // JSON antigo
        if(this.userData.bankData){
          this.nome = this.userData.bankData.name
          this.cpf = this.userData.bankData.cpf
          this.celular = this.userData.telefone

          this.addressZipCode = this.userData.address.zipCode
          this.addressStreet = this.userData.address.streetName
          this.addressNumber = this.userData.address.streetNumber
          this.addressComplement = this.userData.address.additionalAddress
          this.addressNeighborhood = this.userData.address.neighborhood
          this.addressCity = this.userData.address.city
          this.addressState = this.userData.address.state

          if(this.userData.bankData.agency){ this.agencia = this.userData.bankData.agency }
          if(this.userData.bankData.account){
            this.conta = this.userData.bankData.account.split('-')[0]
            this.digito = this.userData.bankData.account.split('-')[1]
          }
          if(this.userData.bankData.bankNumber){ this.bankCod = this.userData.bankData.bankNumber }

          switch (this.userData.bankData.accountType) {
            case 'poupanca':
              this.tipoConta = {tipo:'Conta poupança', val:'poupanca'}
              break;
            default: this.tipoConta = {tipo:'Conta corrente', val:'corrente'}
          }
        }

        // JSON novo Conta Bancária

        if(this.userData.bankData && this.userData.bankData.transfer){
          this.agencia = this.userData.bankData.transfer.agency
          this.conta = this.userData.bankData.transfer.account.split('-')[0]
          this.digito = this.userData.bankData.transfer.account.split('-')[1]
          this.bankCod = this.userData.bankData.transfer.bankNumber

          switch (this.userData.bankData.transfer.accountType) {
            case 'poupanca':
              this.tipoConta = {tipo:'Conta poupança', val:'poupanca'}
              break;
            default: this.tipoConta = {tipo:'Conta corrente', val:'corrente'}
          }
        }

        // JSON novo Pix
        if(this.userData.bankData && this.userData.bankData.pix){
          this.transferType = "2"
          this.pixType = {tipo:this.userData.bankData.pix.keyType, val:this.userData.bankData.pix.keyType}
          switch (this.userData.bankData.pix.keyType) {
            case "celular":
              this.pixKeyCel = this.userData.bankData.pix.key
              break;
            case "cpf":
              this.pixKeyCpf = this.userData.bankData.pix.key
              break;
            case "email":
              this.pixKeyEmail = this.userData.bankData.pix.key
              break;
            default:
              this.pixKeyRandon = this.userData.bankData.pix.key
              break;
          }
        }

      },
      async getBankList(){
        await this.getBanks()
        this.bankList = this.banks
      },

      async submit(){

        if(this.$refs.form.validate()){
          var payload = {
              _id: this.userId,
              event: this.eventId,
              telefone: this.celular.trim().replace(/[^0-9]/g, ''),
              address: {
                zipCode: this.addressZipCode.trim().replace(/[^0-9]/g, ''),
                streetName: this.addressStreet,
                streetNumber: this.addressNumber,
                neighborhood: this.addressNeighborhood,
                city: this.addressCity,
                state: this.addressState
              }
          }
          if(this.addressComplement != ''){ payload.address.additionalAddress = this.addressComplement }

          payload.bankData = {
            name: this.nome,
            cpf: this.cpf.trim().replace(/[^0-9]/g, '')
          }

          if(this.transferType == "2"){ // se for pix

            var type = ''
            if(this.pixType.val) { type = this.pixType.val}else{ type = this.pixType }

            switch (type) {
              case "celular":
                this.pixKey = this.pixKeyCel.trim().replace(/[^0-9]/g, '')
                break;
              case "cpf":
                this.pixKey = this.pixKeyCpf.trim().replace(/[^0-9]/g, '')
                break;
              case "email":
                this.pixKey = this.pixKeyEmail
                break;
              case "aleatoria":
                this.pixKey = this.pixKeyRandon
                break;
              default:
                break
            }

            payload.bankData.pix = {}
            payload.bankData.pix.keyType = type
            payload.bankData.pix.key = this.pixKey

          }else{ // conta bancária
            var bankAccount = this.conta
            if(this.digito != undefined && this.digito.length > 0){ bankAccount = bankAccount+'-'+this.digito }

            if(this.tipoConta.val == 'corrente'){
              this.tipoConta = 'corrente'
            }
            if(this.tipoConta.val == 'poupanca'){
              this.tipoConta = 'poupanca'
            }
            payload.bankData.transfer = {}
            payload.bankData.transfer.account = bankAccount,
            payload.bankData.transfer.accountType = this.tipoConta,
            payload.bankData.transfer.agency = this.agencia,
            payload.bankData.transfer.bankNumber = this.bankCod,
            payload.bankData.transfer.bankName = this.bankList.find(x => x.cod === this.bankCod).banco.split(' - ')[1]
          }

          //console.log(JSON.stringify(payload,null,2))
          await this.updateUser(payload)
        }

      },
      customFilter (item, queryText) {
        // const textOne = item.banco.toLowerCase()
        const textOne = item.banco.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, "")
        const textTwo = item.cod.toLowerCase()
        const searchText = queryText.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, "")
        return textOne.indexOf(searchText) > -1 ||
          textTwo.indexOf(searchText) > -1
      },
      validateCPF(cpf){
          let sum
          let rest
          sum = 0
          if (cpf == "00000000000") return false
          for (let i=1; i<=9; i++) { sum = sum + parseInt(cpf.substring(i-1, i)) * (11 - i) }
          rest = (sum * 10) % 11
          if ((rest == 10) || (rest == 11))  { rest = 0 }
          if (rest != parseInt(cpf.substring(9, 10)) ) { return false }
          sum = 0;
          for (let i = 1; i <= 10; i++) { sum = sum + parseInt(cpf.substring(i-1, i)) * (12 - i) }
          rest = (sum * 10) % 11
          if ((rest == 10) || (rest == 11)) { rest = 0 }
          if (rest != parseInt(cpf.substring(10, 11) ) ) { return false }
          return true
      },
      // -------------------------- CEP ------------------------------
      fill(){
        this.addressStreet = '...'
        this.addressNeighborhood = '...'
        this.addressCity = '...'
        this.addressState = '...'

        this.addressZipCode = this.addressZipCode.trim().replace(/[^0-9]/g, '')
        var self = this

        var request = new XMLHttpRequest();
        request.open('get', "https://api.pagar.me/1/zipcodes/"+this.addressZipCode, true)
        // request.open('get', "https://viacep.com.br/ws/"+this.addressZipCode+"/json/", true)
        request.send()

        request.onload = function () {
          var data = JSON.parse(this.response)
          // console.log(data)
          if(!data.erro) {
            self.addressStreet = data.street
            self.addressNeighborhood = data.neighborhood
            self.addressCity = data.city
            self.addressState = data.state
            self.$refs.numero.focus()
          }else{
            console.log('CEP não encontrado')
            this.cleanForm()
          }
        }
      },
      cleanForm() {
        //Limpa valores do formulário de cep.
        this.addressStreet = ''
        this.addressNumber = ''
        this.addressComplement = ''
        this.addressNeighborhood = ''
        this.addressCity = ''
        this.addressState = ''
      }
    },
    mounted(){
      this.getBankList()
      this.getUserData()
    }

  }
</script>
