<template lang="pug" src="./Details.pug">
</template>

<script>
  import { mapActions, mapGetters } from 'vuex'

  export default{

    data() {
      return{
        options: {},
        pagination: {
          sortBy: ['createdAt', 'releaseDate'],
          sortDesc: ['true', 'false']},
        dataTableLoading: false,
      }
    },
    computed: {
      ...mapGetters(['commissionDetails']),
      totalDocs() {
        if(this.commissionDetails) {
          return this.commissionDetails.length
        }else { return 0 }
      },
      eventId(){
        const queryString = window.location.search
        const urlParams = new URLSearchParams(queryString)
        return urlParams.get('eventId')
      },
      headers() {
        return [
          { text: 'Compra', value: 'createdAt' },
          { text: 'Libera em', value: 'releaseDate' },
          { text: 'Valor líquido', value: 'value'},
          { text: 'Status', value: 'status'}
        ]
      }
    },
    methods: {
      ...mapActions(['getCommissionsByEvent']),
      formatPrice(value) {
        let val = (value/1).toFixed(2).replace('.', ',')
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")
      },
    },
    mounted () {
      this.getCommissionsByEvent({ id: this.eventId })
    }
  }
</script>
