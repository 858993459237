<template lang="pug" src="./WithdrawalConfirmation.pug"></template>

<script>

  import { mapActions } from 'vuex'

  export default{
    data(){
      return{

      }
    },
    computed: {
      hash(){
        var idInput = /[^/]*$/.exec(window.location.pathname).input
        var urlArray = idInput.split("/")
        return urlArray[urlArray.length - 1]
      },
      withdrawConfirmed() {
        return this.$store.state.financial.withdrawConfirmed
      }
    },
    methods: {
      ...mapActions(['confirmWithdraw']),
      async validateHash(){

        await this.confirmWithdraw({ 'token': this.hash })

      }
    },

    mounted() {
      this.validateHash()
    }

  }
</script>
