<template lang="pug" src="./Account.pug"></template>

<script>
import { mapActions, mapGetters } from 'vuex'
export default {
    data: () => ({
        valid: true,
        name: '',
        email: '',
        phone: '',
        createdAt: ''
    }),

    methods: {
        ...mapActions(['getUser'])
    },

    computed: {
        ...mapGetters(['user', 'userData'])
    },

    async mounted() {
        
        await this.getUser({ id: this.user.id })

        this.name = this.userData.name
        this.email = this.userData.email
        this.phone = this.userData.telefone
        this.createdAt = new Date(this.userData.createdAt).toLocaleDateString('pt-BR')
    }
}
</script>