<template src="./Partners.pug" lang="pug"></template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { buildPayloadPagination } from '@/utils/utils.js'

  export default {
    data: () => ({
      dataTableLoading: false,
      options: {},
      pagination: {
        sortBy: 'createdAt',
        sortDesc: ['false']
      },
      search: ''
    }),

    computed: {
      ...mapGetters(['partners']),
      headers() {
        return [
          { text: 'Data', value: 'createdAt' },
          { text: 'Nome', value: 'userName' },
          { text: 'Código utilizado', value: 'promocode' },
          { text: 'Site', value: 'slug' },
          { text: 'Ativado', value: 'activated' },
        ]
      },

      totalDocs() {
        if(this.$store.state.partner.partners){
          return this.$store.state.partner.partners.totalDocs
        }else{
          return 0
        }
      }

    },
    methods: {
      ...mapActions(['getPartners']),

      async doSearch() {

        if(this.search.length>0){
          this.pagination.page = 1
          this.pagination.current = 1
        }

        this.pagination.page = this.options.page
        this.pagination.itemsPerPage = this.options.itemsPerPage
        this.pagination.order = -1

        try {
          
          this.dataTableLoading = true
          await this.getPartners( buildPayloadPagination( this.pagination, this.buildSearch() ) )
          this.dataTableLoading = false

        } catch (error) {
          this.dataTableLoading = false
        }

      },
      buildSearch() {
        return this.search
          ? this.search
          : ''
      }

    },
    
    watch: {
      options: {
        handler () {
          this.doSearch()
        },
        deep: true,
      },
    }

  }

</script>
