<template src="./Withdraw.pug" lang="pug"></template>

<script>
  import { mapActions, mapGetters } from 'vuex'
  import * as types from '@/store/mutation-types'

  import Vue from 'vue'
  import { formatPrice } from "../../utils/formatPrice.js"
  Vue.prototype.formatPrice = formatPrice

  export default{
    name: 'Withdraw',

    data: () => ({
      askForWithdraw: false,
      keyForViewUpdate: 0,
      checkbox: false,
      request: false,
      signature: window.localStorage.getItem( 'signature'),
      hasBankAccount: true,
      valid: true,
      dataTableLoading: false
    }),
    computed: {
      ...mapGetters(['userData', 'withdrawsReport']),
      userId(){
        return this.$jwt.decode(this.$store.state.auth.token).data.id
      },
      validOnEmail: {
        get() {
          return this.$store.state.financial.confirmationSent },
        set(value) {
          this.$store.commit(types.CONFIRMATION_SENT, value) }
      },
      headers() {
        return [
          { text: 'Data', value: 'createdAt' },
          { text: 'E-mail validado', value: 'validateAt' },
          { text: 'Pago em', value: 'paidAt' },
          { text: 'Valor', value: 'value' },
          { text: 'Status', value: 'status'}
        ]
      }
    },
    methods: {
      ...mapActions(['getUser', 'getWithdraws', 'requestWithdraw', 'resendConfirmation']),
      async resendEmail(id){
        await this.resendConfirmation({
          '_id': id,
          'user': this.userId
        })
      },
      async postWithdrawRequest(){
        await this.requestWithdraw()
        this.askForWithdraw = false
        this.getWithdraws()
      },
    },
    mounted(){
      this.getUser({ id: this.userId })
      this.getWithdraws()
      console.log(JSON.stringify(this.withdrawsReport,null,2))
    }

  }
</script>
<style>
  .resgate{
    background: #e8faf1;
  }
  .adiantamento{
    background: #e8f4fa;
  }
</style>
