var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-row',{attrs:{"justify":"center"}},[_c('v-col',[_c('span',{staticClass:"subtitle-1"},[_vm._v("Detalhamento dos presentes recebidos")])])],1),_c('v-row',{staticClass:"mt-4",attrs:{"justify":"center"}},[_c('v-col',{attrs:{"cols":"12","sm":"12","md":"10","lg":"10","xl":"8"}},[_c('div',{class:{'correctExpandableMobile': _vm.$vuetify.breakpoint.smAndDown}},[_c('v-data-table',{staticClass:"elevation-0",attrs:{"item-key":"_id","loading":_vm.dataTableLoading,"headers":_vm.headers,"header-props":{ 'sortByText':'Ordenar por' },"items":_vm.commissionDetails,"sort-by":['nome', 'total'],"sort-desc":[true, false],"multi-sort":"","no-data-text":"Nenhuma transação encontrada","footer-props":{ 'items-per-page-options': [20, 50, 100], 'items-per-page-text':'itens' },"loading-text":"carregando","options":_vm.options,"server-items-length":_vm.totalDocs},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.createdAt",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(new Date(item.createdAt).toLocaleDateString('pt-br')))])]}},{key:"item.releaseDate",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(new Date(item.releaseDate).toLocaleDateString('pt-br'))+" ")])]}},{key:"item.value",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v("R$ "+_vm._s(_vm.formatPrice(item.value)))])]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [(item.status == 'pending')?_c('span',[_vm._v("Pendente")]):_vm._e(),(item.status == 'confirmed')?_c('span',[_vm._v("Confirmado")]):_vm._e()]}}])})],1)])],1),_c('v-row',{staticClass:"hidden-sm-and-down",attrs:{"justify":"center"}},[_c('v-col',{staticClass:"text-center"},[_c('v-btn',{attrs:{"depressed":""},on:{"click":function($event){return _vm.$router.go(-1)}}},[_vm._v("Voltar")])],1)],1),_c('v-footer',{staticClass:"hidden-md-and-up",attrs:{"fixed":"","inset":"","app":"","color":"white","elevation":"3"}},[_c('v-row',{attrs:{"justify":"center","dense":""}},[_c('v-col',{attrs:{"cols":"8","sm":"8"}},[_c('v-btn',{attrs:{"depressed":""},on:{"click":function($event){return _vm.$router.go(-1)}}},[_vm._v("Voltar")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }