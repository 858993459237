<template src="./Report.pug" lang="pug"></template>

<script>
  import Vue from 'vue'
  import { formatPrice } from "../../utils/formatPrice.js"
  Vue.prototype.formatPrice = formatPrice
  import { buildPayloadPagination } from '@/utils/utils.js'
  import { mapActions, mapGetters } from 'vuex'

  export default{
    name: 'Report',

    data: () => ({
      shareDialog: false,
      siteUrl: process.env.VUE_APP_SHARE_URL,
      linkToShare: '',
      urlCopied: false,
      options: {},
      pagination: {
        sortDesc: ['false']},
      dataTableLoading: false,

    }),
    computed: {
      ...mapGetters(['comissionReport']),
      promos() {
        return require('../common/promos.js')
      },
      tableReport() {
        if(this.$store.state.financial.comissionReport.commissionsByEvent){
          return this.$store.state.financial.comissionReport.commissionsByEvent.docs
        }else{
          return []
        }
      },
      totalDocs() {
        if(this.$store.state.financial.comissionReport.commissionsByEvent){
          return this.$store.state.financial.comissionReport.commissionsByEvent.docs.totalDocs
        }else{
          return 0
        }

      },
      headers() {
        return [
          { text: 'Data de cadastro', value: 'createdAt' },
          { text: 'Nome', value: 'name' },
          { text: 'Código utilizado', value: 'code' },
          // { text: 'Status', value: 'status' },
          // { text: 'Liberado', value: 'released' },
          // { text: 'A liberar', value: 'toBeReleased' },
          { text: 'Total', value: 'total' },
          { text: 'Ações', value: 'actions' }
        ]
      }
    },
    methods: {
      ...mapActions(['getComissionsReport']),
      detail(id) {
        this.$router.push({
          name: 'financial-details',
          query: { eventId: id }
        })
      },
      formatPrice(value) {
        let val = (value/1).toFixed(2).replace('.', ',')
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")
      },

      showShareLink(code) {
        this.linkToShare = code
        this.shareDialog = true
      },

      copyCodeUrl(str){
        var self = this
        navigator.clipboard.writeText(str).then(function() {
          self.shareDialog = false
          self.urlCopied = true
        }, function(err) {
          console.error('Erro ao copiar o link: ', err);
        })
      },

      async doSearch() {
        this.pagination.page = this.options.page
        this.pagination.itemsPerPage = this.options.itemsPerPage

        try {
          this.dataTableLoading = true

          await this.getComissionsReport( [buildPayloadPagination(this.pagination)] )
          this.dataTableLoading = false

        } catch (error) {
          this.dataTableLoading = false
        }

      },
    },
    mounted(){
      // this.doSearch()
    },
    watch: {
      options: {
        handler () {
          this.doSearch()
        },
        deep: true,
      },
    }

  }
</script>
